import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_switch = _resolveComponent("van-switch");
  const _component_van_cell = _resolveComponent("van-cell");
  return _openBlock(), _createBlock(_component_van_cell, {
    center: "",
    title: "隐藏分享个人信息"
  }, {
    "right-icon": _withCtx(() => [_createVNode(_component_van_switch, {
      value: $options.meStore.shareHiddenInfo,
      "model-value": $options.meStore.shareHiddenInfo,
      size: "24",
      onInput: $options.meStore.getOrSetShareHiddenInfo,
      "onUpdate:modelValue": $options.meStore.getOrSetShareHiddenInfo
    }, null, 8, ["value", "model-value", "onInput", "onUpdate:modelValue"])]),
    _: 1
  });
}